exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-showreel-js": () => import("./../../../src/pages/showreel.js" /* webpackChunkName: "component---src-pages-showreel-js" */),
  "component---src-templates-private-project-js": () => import("./../../../src/templates/PrivateProject.js" /* webpackChunkName: "component---src-templates-private-project-js" */),
  "component---src-templates-public-project-js": () => import("./../../../src/templates/PublicProject.js" /* webpackChunkName: "component---src-templates-public-project-js" */)
}

